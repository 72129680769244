%radius {
  &:nth-child(n + 1) {
    &,
    & > .euiButtonIcon,
    & > * > .euiButtonIcon {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
  &:nth-child(n - 1) {
    &,
    & > .euiButtonIcon,
    & > * > .euiButtonIcon {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &:first-child {
    &,
    & > .euiButtonIcon,
    & > * > .euiButtonIcon {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }

  &:last-child {
    &,
    & > .euiButtonIcon,
    & > * > .euiButtonIcon {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.leaflet-custom-control-container {
  display: flex;
  flex-direction: column;

  &:empty {
    margin: 0;
  }

  & > .euiButtonIcon:hover:not(:disabled),
  & > * > .euiButtonIcon:hover:not(:disabled),
  & > .euiPopover > .euiPopover__anchor > .euiButtonIcon:hover:not(:disabled) {
    transform: scale(1.05);
  }

  & > * {
    @extend %radius;

    &:nth-child(n + 1) {
      position: relative;

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        content: '';
        display: block;
        height: 1px;
        width: 100%;
        background-color: var(--leaflet-button-delimiter);
      }
    }

    &:last-child {
      &::after {
        content: none;
      }
    }
  }
}
