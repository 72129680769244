@import 'node_modules/@elastic/eui/src/themes/legacy/_colors_light.scss';

.leaflet-area-selecting {
  user-select: none;
  cursor: crosshair;
}

.leaflet-marker-control-container {
  transform: none;
  border: none;
  background: none;

  &__reactContainer {
    position: absolute;
    bottom: 3px;
    right: 0;
  }
}
