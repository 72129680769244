.leaflet-control-beta-warning {
  &__content {
    bottom: 0;
    left: 50%;
    width: 80%;
    transform: translate(-50%);
    position: absolute;
    z-index: 999;
    padding-top: 10px;
  }
}
