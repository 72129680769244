@use 'sass:selector';

@mixin unify-parent($child) {
  @at-root #{selector.unify(&, $child)} {
    @content;
  }
}

%file {
  position: relative;
  border-radius: 3px;
  border: 1px solid var(--color-file-border);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 128px;
  height: 170px;
  overflow: hidden;

  & > .controls {
    opacity: 0;
    position: absolute;
    bottom: 7px;
    left: 7px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  & > .title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 4px 7px;
    font-size: 12px;
    font-weight: 600;
    opacity: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #fff;
  }

  &:hover {
    &::before {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
      opacity: 1;
    }

    & > .title {
      opacity: 1;
    }

    & > .controls {
      opacity: 1;
    }
  }
}

%thumbnail {
  @include unify-parent('img') {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @include unify-parent('svg') {
    height: 80%;
    fill: var(--color-file-icon);
  }
}

.fileCard {
  @extend %file;

  & > .thumbnail {
    @extend %thumbnail;
  }
}
