.leaflet-control-geocoder {
  &__content {
    top: 0;
    left: 50%;
    width: 80%;
    transform: translate(-50%);
    position: absolute;
    z-index: 1000;
    padding-top: 10px;
  }
}
