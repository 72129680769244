%line {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: var(--profiler-margin-size);

  &[data-level='0'] > %key {
    color: var(--profiler-text-key-first);
    font-weight: 500;
  }
}

%key {
  color: var(--profiler-text-key);
  word-break: break-all;
}

%value {
  color: var(--profiler-text);
  font-weight: 500;
  word-break: break-word;
}

%files {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

%card {
  font-family: Roboto Mono, monospace;
  font-size: 14px;
  line-height: 14px;
  white-space: pre-wrap;
  width: 100%;
}

.cardData {
  @extend %card;

  & .line {
    @extend %line;

    & > .key {
      @extend %key;
    }

    & > .value {
      @extend %value;
    }
  }
}

.profilerCardData {
  @extend %card;
  & .line {
    @extend %line;
    grid-template-columns: 1fr 2fr;
    margin-bottom: 2px;
    &[data-level='0'] {
      &:not(:first-child) {
        padding-top: var(--profiler-margin-size);
      }
      margin-bottom: var(--profiler-margin-size);
    }
    line-height: 19px;
    & > .key {
      @extend %key;
    }

    & > .value {
      @extend %value;
    }

    & > .files {
      @extend %files;
    }
  }
}

.separator {
  display: block;
  position: relative;
  line-height: 1px;
  border-top: 1px solid var(--profiler-separator-color);
  width: 50%;
  margin-right: 0px;
  margin-left: auto;
  margin-block: 10px;
}

.line[data-level="0"]:not(:first-of-type) {
  border-top: 1px solid var(--profiler-separator-color);
  margin-top: 12px;
  padding-top: 10px;
}

.profilerCardData .separator {
  width: 66.7%;
}