@import 'node_modules/moyarenko-markdown/styles/main.scss';


:root {
    --background-row-size-line: 2px;
    --background-row-size-color: 16px;

    --profiler-text: #69707D;
    --profiler-ident-size: 16px;
    --profiler-margin-size: 6px;
    --profiler-margin-half-size: 4px;
    --profiler-margin-half-double-size: 10px;
    --profiler-margin-double-size: 12px;

    --react-international-phone-height: 32px;
    --react-international-phone-border-radius: 6px;
    --react-international-phone-font-size: 14px;

    --border-angle: 0turn;
}
.euiLink  [class*="ScreenReaderOnly"] {
    display: none;
}
.euiDataGridRowCell  p[class$=euiScreenReaderOnly] {
    visibility: hidden;
}

.EuiDataEmptyTopBorder .euiDataGridHeaderCell {
    border-top: none!important;
}

.euiContextMenuItem:hover {
    background-color: rgba(0, 119, 204, 0.1);
}

.euiContextMenuItem:hover, .euiContextMenuItem:focus {
    text-decoration: none!important;
}

.euiDataGridRow--workInProgress {
    background-color: transparent!important;
    background: repeating-linear-gradient(
        135deg,
        var(--color-row-progress),
        var(--color-row-progress) var(--background-row-size-line),
        var(--color-row-delimiter) var(--background-row-size-line),
        var(--color-row-delimiter) var(--background-row-size-color)
    );
}

.euiDataGridRow--workInProgress:hover {
    background-color: transparent!important;
    background: repeating-linear-gradient(
        135deg,
        var(--color-row-progress),
        var(--color-row-progress) var(--background-row-size-line),
        var(--color-row-progress-hover) var(--background-row-size-line),
        var(--color-row-progress-hover) var(--background-row-size-color)
    );
}


.euiDataGridRow--isValid {
    background-color: var(--color-row-valid)!important;
}

.euiDataGridRow--isValid:hover {
    background-color: var(--color-row-valid-hover)!important;
}


.euiDataGridRow--isInValid {
    background-color: var(--color-row-invalid)!important;
}

.euiDataGridRow--isInValid:hover {
    background-color: var(--color-row-invalid-hover)!important;
}


.euiDataGridRow--workInProgress--isInValid{
    background-color: transparent!important;
    background: repeating-linear-gradient(
        135deg,
        var(--color-row-delimiter),
        var(--color-row-delimiter) var(--background-row-size-line),
        var(--color-row-invalid) var(--background-row-size-line),
        var(--color-row-invalid) var(--background-row-size-color)
    );
}


.euiDataGridRow--workInProgress--isInValid:hover{
    background-color: transparent!important;
    background: repeating-linear-gradient(
        135deg,
        var(--color-row-delimiter),
        var(--color-row-delimiter) var(--background-row-size-line),
        var(--color-row-invalid-hover) var(--background-row-size-line),
        var(--color-row-invalid-hover) var(--background-row-size-color)
    );
}

.euiDataGridRow--workInProgress--isValid{
    background-color: transparent!important;
    background: repeating-linear-gradient(
        135deg,
        var(--color-row-delimiter),
        var(--color-row-delimiter) var(--background-row-size-line),
        var(--color-row-valid) var(--background-row-size-line),
        var(--color-row-valid) var(--background-row-size-color)
    );
}


.euiDataGridRow--workInProgress--isValid:hover{
    background-color: transparent!important;
    background: repeating-linear-gradient(
        135deg,
        var(--color-row-delimiter),
        var(--color-row-delimiter) var(--background-row-size-line),
        var(--color-row-valid-hover) var(--background-row-size-line),
        var(--color-row-valid-hover) var(--background-row-size-color)
    );
}

input {
    outline: none!important;
}

.euiHealth--reverse .euiFlexGroup {
    flex-direction: row-reverse;
}



.themeSwitch .euiSwitch__icon {
    display: none;
}

.themeSwitch .euiSwitch__label {
    display: none;
}

.euiText.accentText {
    color: var(--color-accent-text);
}
.euiContextMenuPanel {
    box-shadow: 0px 0px 10px 0px var(--color-accent-shadow);
    border-radius: 5px;
}
.euiToolTip {
    background-color: var(--bg-tooltip) !important;
    color: #2E2E2E !important;
    & > div {
        background-color: var(--bg-tooltip) !important;
    }
}

.P {
    font-family: Roboto Mono,monospace;
    font-size: 14px;
    line-height: 14px;
    user-select: none;
    white-space: pre-wrap;

    & * {
        user-select: text;
    }

    & .highlight-child, & .hightlight {
        user-select: none;
    }

    &__line {
        width: 100%;
        display: block;
        margin-bottom: var(--profiler-margin-size);
    }

    &__key {
        vertical-align: top;
        display: inline-block;
        color: var(--profiler-text-key);
        word-break: break-all;
        line-height: 20px;


        & + .P__array > .P__arrayItem:first-child {
            margin-top: var(--profiler-margin-size);
        }

        &_first {
            font-weight: 500;
            color: var(--profiler-text-key-first);
            margin-top: var(--profiler-margin-size);
            margin-bottom: var(--profiler-margin-double-size);

            &_compressed {
                margin-bottom: 0;
            }

            & + .P__array > .P__arrayItem {
                margin-top: 0;
            }
        }

    }

    &__array {
        display: block;
    }

    &__arrayItem{
        width: 100%;
        display: block;

        & + & {
            margin-top: var(--profiler-margin-half-double-size);
        }
    }

    &__value {
        display: inline-block;
        color: var(--profiler-text);
        font-weight: 500;
        word-break: break-word;
        vertical-align: top;
        line-height: 20px;

        &_first {
            margin-top: var(--profiler-margin-size);
        }
        &_first.P__value_badge {
            margin-top: var(--profiler-margin-half-size);
        }
    }

    %selection {
        &::selection {
            background-color: transparent;
        }
    }

    &__tab {
        @extend %selection;
        vertical-align: top;
        display: inline-block;
        width: 0;
        height: 0;
        line-height: 0;
        white-space: pre-wrap;
    }

    &__br {
        @extend %selection;
        display: block;
        width: 0;
        height: 0;
        line-height: 0;
        white-space: pre-wrap;
        color: transparent;

        &_first {
            display: inline-block;
        }
    }
}

.webkit {
    .P__br {
        display: none;
    }
}

@media print {
    [aria-label="print"] {
        display: none!important;
        visibility: hidden;
    }

    [aria-label="print-break"] {
        page-break-after: always;
    }

    .echContainer canvas {
        width: 100%!important;
        height: 300px!important;
        aspect-ratio: 16/4!important;
    }
}

.hightlight {
    &:before {
        content: attr(data-unselectable);
    }
    background-color: var(--color-hightlighter);

    .highlight-parent[active="true"] & {
        background-color: var(--color-hightlighter-double);
    }
}

.not-hightlight {
    &:before {
        content: attr(data-unselectable);
    }
}

.euiDatePickerRange {
    & .euiDatePicker {
        height: 32px!important;
    }
}

.euiMarkdownFormat ul {
    font-family: "Roboto Mono", Menlo, Courier, monospace;
}

.highlight-parent {
    position: relative;
    z-index: 1;
    display: inline-block;
}



.highlight-child {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    color: transparent;
    user-select: none;
}

.card-data-highlight {
    word-break: break-word;
    line-height: 19px;
    background-color: var(--color-hightlighter);
}

.trustBadge {
    font-family: Roboto Mono,monospace;
    font-weight: 700!important;
}

.leakText {
    font-family: Roboto Mono,monospace;
}

.profiler-list {
    overflow-x: hidden!important;
    overflow-y: scroll!important;
    outline: none!important;
}

.app-popup-leaflet {
    & a.leaflet-popup-close-button, .leaflet-popup-tip-container {
        display: none;
    }

    & .leaflet-popup-content-wrapper {
        background-color: transparent;
        box-shadow: none;
        border-radius: 0;
    }

    & .leaflet-popup-content {
        width: auto!important;
    }
}

.phone-input-container {
    position: relative;
    input {
        flex-grow: 1;
    }
    .react-international-phone-country-selector {
       position: static;
    }
    .react-international-phone-country-selector-button:hover {
        background-color: var(--react-international-phone-background-color);
    }
    .react-international-phone-country-selector-dropdown {
        top: 32px;
        width: 100%;
        max-height: 116px;
        box-shadow: none;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border: 1px solid var(--react-international-phone-border-color);
        border-top: none;
    }
}
.filter-option {
    height: 28px;
    padding: 4px;
    line-height: 1.4;
    &:hover {
        background-color: var(--react-international-phone-selected-dropdown-item-background-color);
    }
}

button.accentTextButton {
    color: var(--color-accent-text);
    svg {
        fill: var(--color-accent-text);
    }
}

.euiTitle.accentTitle {
    color: var(--color-accent-title);
}

@keyframes bg-spin {
    to {
        --border-angle: 1turn;
      }
}
.euiPanel.border-animation {
    border: solid 1px transparent;
    border-radius: 6px;
    background-position: center center;
    background: conic-gradient(from var(--border-angle), var(--bg-color-app-card), var(--bg-color-app-card) 5%, var(--bg-color-app-card) 60%, var(--bg-color-app-card) 95%) padding-box, conic-gradient(from var(--border-angle), transparent 25%, var(--color-accent-text), #E40000 99%, transparent) border-box;
  
    animation: bg-spin 5s linear infinite;
  
    &:hover {
    animation-play-state: paused;
    box-shadow: 2px 2px 5px 0px var(--color-accent-shadow);
    }
  }

  @property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
  }

  .visibleLine {
    max-height: 500px;
    padding-top: 0px;
    padding-bottom: 8px;
    transform: translateY(0);
    opacity: 1;
    transition: all 400ms ease-in-out;
  }

  .visually_hidden {
    max-height: 0px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    transform: translateY(-50px) !important;
    opacity: 0 !important;
    pointer-events: none;
    overflow: hidden;
  }